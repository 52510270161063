import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/hausman/app/src/client/template.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnimateIntroBanner = makeShortcode("AnimateIntroBanner");
const RowLines = makeShortcode("RowLines");
const Video = makeShortcode("Video");
const Decoration = makeShortcode("Decoration");
const Block = makeShortcode("Block");
const Heading = makeShortcode("Heading");
const LearnMore = makeShortcode("LearnMore");
const AnimateIntroRow = makeShortcode("AnimateIntroRow");
const AnchorCTA = makeShortcode("AnchorCTA");
const ParagraphSlider = makeShortcode("ParagraphSlider");
const Paragraph = makeShortcode("Paragraph");
const Odometer = makeShortcode("Odometer");
const Row = makeShortcode("Row");
const AnimateIntroBlock = makeShortcode("AnimateIntroBlock");
const Anchor = makeShortcode("Anchor");
const BlockNews = makeShortcode("BlockNews");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnimateIntroBanner id="banner" className="background_image" background={<><RowLines mdxType="RowLines" />
      <Video video="home-banner" thumbnail={<Decoration from="/home/banner-bg.jpg" mdxType="Decoration" />} autoPlay={true} loop={true} muted={true} controls={false} mdxType="Video" /></>} mdxType="AnimateIntroBanner">
  <Block mdxType="Block">
    <Heading mdxType="Heading">
      Integrated Operations
      <br /> & Maintenance Solutions
    </Heading>
    <Heading level={3} mdxType="Heading">
      We manage, operate, and maintain facilities of any type and size in
      Lebanon and the Middle East region.
    </Heading>
    <LearnMore elementId="context" mdxType="LearnMore" />
  </Block>
    </AnimateIntroBanner>
    <AnimateIntroRow id="context" background={<RowLines mdxType="RowLines" />} mdxType="AnimateIntroRow">
  <Block mdxType="Block">
  <Heading mdxType="Heading">A Powerful<br /> Combination of<br /> Expertise, Technology,<br /> and Legacy</Heading>
  <AnchorCTA to="/services" mdxType="AnchorCTA">What we can do for you</AnchorCTA>
  </Block>
  <Block className="intro_from_below" mdxType="Block">
  <ParagraphSlider mdxType="ParagraphSlider">
  <Paragraph mdxType="Paragraph">As a member of MAN Holding, Hausman is equipped with the industrial knowledge, technical expertise and cutting-edge information technology to make sure your building, facility or warehouse runs smoothly throughout its life cycle.</Paragraph>
  <Paragraph mdxType="Paragraph">We strive to improve your facility’s performance and keep business interruptions to a minimum in a cost-effective way, while prioritizing health and safety to the facility occupants: tenants, customers, staff and workers alike.</Paragraph>
  </ParagraphSlider>
  </Block>
    </AnimateIntroRow>
    <AnimateIntroRow className="content" background={<RowLines mdxType="RowLines" />} mdxType="AnimateIntroRow">
  <Block className="content_block" mdxType="Block">
  <Heading mdxType="Heading">Our Solutions</Heading>
  <div>
  <Paragraph mdxType="Paragraph">We strive to improve your facility’s performance and keep business interruptions to a minimum in a cost-effective way – while prioritizing health and safety to the facility occupants: tenants, customers, staff and workers alike.</Paragraph>
  <AnchorCTA to="/solutions" mdxType="AnchorCTA">Read more</AnchorCTA>
  </div>
  </Block>
  <Block className="decoration_block" mdxType="Block">
    <div className="decoration_wrapper"><Decoration from="/home/integrated-solution.jpg" mdxType="Decoration" /></div>
  </Block>
    </AnimateIntroRow>
    <AnimateIntroRow className="featured" background={<><RowLines mdxType="RowLines" /><Decoration from="/home/warehouses-bg.jpg" mdxType="Decoration" /></>} mdxType="AnimateIntroRow">
  <Block className="content_block" mdxType="Block">
  <Heading level={4} mdxType="Heading">Featured</Heading>
  <Heading level={2} mdxType="Heading">Warehouses</Heading>
  <Paragraph mdxType="Paragraph">Ensuring the smooth functioning of warehouses without any interruption.
  </Paragraph>
  <AnchorCTA to="/sector/warehouses" mdxType="AnchorCTA">See our Warehouse Projects</AnchorCTA>
  </Block>
  <Block className="statistic_block" mdxType="Block">
  <Paragraph mdxType="Paragraph">Up to <strong><Odometer startValue={100000} mdxType="Odometer">150000</Odometer>m<sup>2</sup></strong> optimized warehouse space</Paragraph>
  </Block>
    </AnimateIntroRow>
    <Row className="content flipped" background={<RowLines mdxType="RowLines" />} mdxType="Row">
  <AnimateIntroBlock className="content_block" mdxType="AnimateIntroBlock">
    <Heading mdxType="Heading">Hausman’s<br /> services.</Heading>
    <div>
      <Paragraph mdxType="Paragraph">Through meticulous assessments, diligent applications, and strong coordination, all our services converge towards one purpose: our clients’ complete satisfaction and peace of mind, so they can focus on their core business.
      </Paragraph>
      <AnchorCTA to="/services" mdxType="AnchorCTA">Read more</AnchorCTA>
    </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock className="decoration_block" mdxType="AnimateIntroBlock">
    <div className="decoration_wrapper">
      <Decoration from="/home/information-management.jpg" mdxType="Decoration" />
    </div>
  </AnimateIntroBlock>
    </Row>
    <AnimateIntroRow className="featured" background={<><RowLines mdxType="RowLines" />
      <Decoration from="/home/industrial-camp-bg.jpg" mdxType="Decoration" /></>} mdxType="AnimateIntroRow">
  <Block className="content_block" mdxType="Block">
  <Heading level={4} mdxType="Heading">Featured</Heading>
  <Heading level={2} mdxType="Heading">Industrial camp accommodation
  </Heading>
  <Paragraph mdxType="Paragraph">Managing workforce accommodation in Qatar and Lebanon.</Paragraph>
  <AnchorCTA to="/sector/industrial-sites-and-factories" mdxType="AnchorCTA">See our Industrial Camp Projects</AnchorCTA>
  </Block>
  <Block className="statistic_block" mdxType="Block">
    <Paragraph mdxType="Paragraph">Around <strong><Odometer startValue={1000} mdxType="Odometer">5000</Odometer></strong> workers</Paragraph>
  </Block>
    </AnimateIntroRow>
    <AnimateIntroRow id="legacy" className="content flipped" background={<RowLines mdxType="RowLines" />} mdxType="AnimateIntroRow">
  <Block className="content_block" mdxType="Block">
    <Heading mdxType="Heading">
      Legacy: A Network
      <br /> You Can Lean On
    </Heading>
    <div>
      <Paragraph className="big" mdxType="Paragraph">
        With Hausman, benefit from the quality standards of the MAN Holding network. 
      </Paragraph>
      <Paragraph mdxType="Paragraph">
        For any complementary services you might need, we can work closely with a range of partners specialized in construction and engineering. The Group includes MAN Enterprise, an international leader in the construction industry, and Metrix, among the largest electro-mechanical contractors in the Middle East and Africa.
      </Paragraph>
      <AnchorCTA to="/about" mdxType="AnchorCTA">Learn more about us</AnchorCTA>
    </div>
  </Block>
  <Block className="decoration_block" mdxType="Block">
    <ul className="decoration_wrapper">
      <li>
        <Anchor to="http://www.manenterprise.com" mdxType="Anchor">
          <Decoration from="/partner-companies/man-enterprise.svg" alt="Man Enterprise" mdxType="Decoration" />
        </Anchor>
      </li>
      <li>
        <Anchor to="http://www.tecmanindustry.com" mdxType="Anchor">
          <Decoration from="/partner-companies/tecman.svg" alt="Tecman" mdxType="Decoration" />
        </Anchor>
      </li>
      <li>
        <Anchor to="http://www.metrixmep.com" mdxType="Anchor">
          <Decoration from="/partner-companies/metrix.svg" alt="Metrix" mdxType="Decoration" />
        </Anchor>
      </li>
    </ul>
  </Block>
    </AnimateIntroRow>
    <Row className="whitespace" background={<RowLines mdxType="RowLines" />} mdxType="Row" />
    <Row id="news" background={true} data-background-animation="false" mdxType="Row">
  <BlockNews id="news_block" className="content_block" mdxType="BlockNews" />
    </Row>
    <Row className="whitespace" background={<RowLines mdxType="RowLines" />} mdxType="Row" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      